/* Fonts */
// @import url('https://fonts.googleapis.com/css?family=Nunito');

/* Variables */
@import 'variables';

/* Bootstrap */
// @import '~bootstrap/scss/bootstrap';

/* admin lte */
@import "~admin-lte/plugins/select2/css/select2.min.css";
//@import "~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";
@import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
@import "~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css";
@import "~admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css";
@import "~admin-lte/plugins/bootstrap-slider/css/bootstrap-slider.min.css";
@import "~admin-lte/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.min.css";
@import "~admin-lte/plugins/ion-rangeslider/css/ion.rangeSlider.min.css";
@import "~admin-lte/plugins/toastr/toastr.min.css";
@import '~jquery-ui/themes/base/all.css';
@import '~daterangepicker/daterangepicker.css';

input:-webkit-autofill { 
    -webkit-background-clip: text;
}